import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import ds from './tokens'
const { theme} = ds;

export const gridContainer = ({withPageConstraints = false}) => {
  let style = {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    width: '100%'
  }
  if(withPageConstraints) {
    style.maxWidth = theme.maxWidth.page;
    style.marginLeft = 'auto';
    style.marginRight = 'auto';
    style.paddingLeft = theme.spacing[8];
    style.paddingRight = theme.spacing[8];
    style.boxSizing = 'border-box';
  }
  return css(style)
}

export const gridCol = ({width = [], widthOffset = []}) => {
  const [sm, md, lg] = width
  const [smOffset, mdOffset, lgOffset] = widthOffset
  return css({

    '@media (min-width: 1024px)': {
      flexBasis: `${((lg || md || sm) / 12) * 100}%`,
      marginLeft: `${((lgOffset || mdOffset || smOffset) / 12) * 100}%`
    },

    '@media (max-width: 1024px)': {
      flexBasis: `${((md || sm) / 12) * 100}%`,
      marginLeft: `${((mdOffset || smOffset) / 12) * 100}%`
    },

    '@media (max-width: 768px)': {
      flexBasis: `${(sm / 12) * 100}%`,
      marginLeft: `${(smOffset / 12) * 100}%`
    },

  })
}

const Grid = styled.div`
  ${p => gridContainer(p)};
`

const Col = styled.div`
  ${p => gridCol(p)};
`

Grid.Col = Col

Grid.Col.propTypes = {
  width: PropTypes.array
}

export default Grid
