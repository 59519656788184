import React from 'react'
import ReduxWrapper from './src/state/ReduxWrapper'

/* Semantic CSS library */
import './gen/tailwind.scss';
import { UIProvider } from './src/helper/mdx'

export const wrapRootElement = ({ element }) => {
  return <>
    <ReduxWrapper>
      <UIProvider>
        {element}
      </UIProvider>
    </ReduxWrapper>
  </>
}
