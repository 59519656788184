import getInitialState from './getInitialState'

const actionType = {
  SET_AUTH_USER: 'SET_AUTH_USER',
  CLEAR_AUTH: 'CLEAR_AUTH'
}

export const setAuthentication = ({ user }) => {
  return {
    type: actionType.SET_AUTH_USER,
    user: user,
  }
}

export const clearAuthentication = () => ({
  type: actionType.CLEAR_AUTH
})

const reducer = (state = getInitialState('auth'), { type, ...payload }) => {
  switch (type) {
    case actionType.SET_AUTH_USER:
      return {
        ...state,
        isAuthenticated: payload.user ? true : false,
        user: payload.user,
      }

    case actionType.CLEAR_AUTH:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }

    default:
      return state
  }
}

export default reducer;