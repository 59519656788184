import React from 'react'
import { Provider } from 'react-redux'
import {
  applyMiddleware,
  compose,
  createStore as reduxCreateStore,
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '.'

const sagaMiddleware = createSagaMiddleware()

const createStore = () =>
  reduxCreateStore(
    rootReducer,
    compose(
      applyMiddleware(sagaMiddleware)
    )
  )

// sagaMiddleware.run(mySaga)

export default ({ children }) => (
  <Provider store={createStore()}>{children}</Provider>
)
