import React from 'react'
import Grid from '../ui/Grid'
import { makeWebPart } from '../utils/webPart'
import { css } from 'linaria'
// import { getComponentsByLegoName } from '../utils/lego'

export const PageContent = p => {

  // const sidebarBlock = getComponentsByLegoName(p.children, 'PageSidebar')

  return <Grid withPageConstraints>
    <Grid.Col width={[12, 10, 10]} widthOffset={[0, 0, 1]}>
      <div className={styles.body}>
        <div className={styles.bodyContent}>
          {p.children}
        </div>
      </div>
    </Grid.Col>
  </Grid>
}


export default makeWebPart('Page', {
  Content: PageContent
})

const styles = {
  body: css`
    position: relative;
    padding: 20px 0 0 240px;
  `,
}

