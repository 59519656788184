import React from 'react'
import { makeWebPart } from '../../utils/webPart'
import { css } from 'linaria'
import { makeLegoComponent } from '../../utils/lego'
import { Dropdown, Menu } from 'semantic-ui-react'
import { navigate } from 'gatsby';
import { useLocation } from "@reach/router"

export const Sidebar = makeLegoComponent(({ navItems = [] }) => {

  const location = useLocation();

  return <div className={styles.sideBarContainer}>
    <Menu vertical className={styles.sideBar}>
      {navItems.map((sectionItems, ni) => {
        return <Menu.Item key={ni}>
          <Menu.Menu>
            {sectionItems.map(({ text, action, dropdown }, si) => {
              if (dropdown && dropdown.length > 0) {
                return <Dropdown item text={text} as="a" key={si}>
                  <Dropdown.Menu>
                    {dropdown.map((item, di) => <Dropdown.Item key={di}>{item.text}</Dropdown.Item>)}
                  </Dropdown.Menu>
                </Dropdown>
              }
              else {
                return <Menu.Item
                  key={si}
                  onClick={() => {
                    switch (action) {
                      case 'navigate:submit-paper':
                        navigate(`${location.pathname}/submit-paper`)
                        break
                      default:
                        console.error(`menu item ${action} has no handler`)
                        break
                    }
                  }}
                  as='a' >{text}</Menu.Item>
              }
            })}
          </Menu.Menu>
        </Menu.Item>
      })}

    </Menu>
  </div>
}, { name: 'PageSidebar' })

export default makeWebPart('Event', {
  Sidebar: Sidebar,
})

const styles = {
  sideBarContainer: css`
    position: absolute;
    left: 0;
    top: 100px;
  `,
  sideBar: css`
    &&& { width: 220px; }
  `,
}
