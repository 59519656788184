import React from 'react';

export const makeLegoComponent = (Component, { name } = {}) => {
  Component.defaultProps = Component.defaultProps || {}
  Component.defaultProps.__legoName = name || Component.displayName
  return Component
}

export const getLegoName = element => {
  return element && element.props.__legoName;
}

export const getComponentsByLegoName = (children, legoName) => {
  let legoChildren = [];
  React.Children.forEach(children, (child) => {
    if(legoName === getLegoName((child))) {
      legoChildren.push(child)
    }
  });
  return legoChildren;
}
