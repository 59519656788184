import React from 'react'
import { makeWebPart } from '../utils/webPart'

export default makeWebPart('', {
  h1: ({ children, ...props }) => <h1 className={styles['h1']} {...props}>{children}</h1>,
  h2: ({ children, ...props }) => <h2 className={styles['h2']} {...props}>{children}</h2>,
  h3: ({ children, ...props }) => <h3 className={styles['h3']} {...props}>{children}</h3>,
  h4: ({ children, ...props }) => <h4 className={styles['h4']} {...props}>{children}</h4>,
  h5: ({ children, ...props }) => <h5 className={styles['h5']} {...props}>{children}</h5>,
  h6: ({ children, ...props }) => <h6 className={styles['h6']} {...props}>{children}</h6>,
  p: ({ children, ...props }) => <p className={styles['p']} {...props}>{children}</p>,
  a: ({ children, ...props }) => <a className={styles['a']} {...props}>{children}</a>,
  strong: ({ children, ...props }) => <strong className={styles['strong']} {...props}>{children}</strong>,
})

const styles = {
  h1: 'text-5xl',
  h2: 'text-4xl',
  h3: 'text-3xl',
  h4: 'text-2xl',
  h5: 'text-xl',
  h6: 'text-lg',
  p: 'text-base font-normal leading-relaxed',
  a: 'text-pri-50 hover:underline cursor-pointer',
  strong: 'font-semibold',
}
