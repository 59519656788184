import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import EventParts from '../widget/event/webparts'
import PageParts from '../widget/Page.webpart'
import PageText from '../widget/PageText.webpart'
import { MDXProvider } from '@mdx-js/react'

export const defaultComponents = {
  ...EventParts,
  ...PageParts,
  ...PageText
}

export const renderMdx = (data) => {
  return <MDXRenderer>{data}</MDXRenderer>
}

export const UIProvider = props => {
  return <MDXProvider components={defaultComponents}>{props.children}</MDXProvider>
}
